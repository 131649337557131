<template>
  <div></div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import shortName from "@/mixins/shortName";
import { UpdateTimeZone } from "@/api";

export default {
  props: {
    currentUser: {
      type: Object,
    },
  },
  components: {},
  mixins: [shortName],
  data: () => ({
    browserTimezone: "",
  }),
  created() {},
  mounted() {
    var browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.browserTimezone = browserTimezone;

    if (
      JSON.stringify(this.currentUser.timezone) === undefined ||
      JSON.stringify(this.currentUser.timezone) === null ||
      JSON.stringify(this.currentUser.timezone) == "null"
    ) {
      this.ConfirmTimeZone();
    }
  },

  methods: {
    async ConfirmTimeZone() {
      const payload = {
        timezone: this.browserTimezone,
      };
      UpdateTimeZone(payload)
        .then((response) => {
         // console.log("UpdateTimeZone : ", response);
        })
        .catch((error) => {
          //alert(error);
        });
    },
  },
  computed: {
    // ...mapState("userManagement", ["currentUser"]),
  },
};
</script>
